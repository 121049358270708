























































import { getCounter, quickpayBindBank } from '@/api'
import router from '@/router'
import { AppModule } from '@/store/modules/app'
import { Component, Vue, Watch } from 'vue-property-decorator'
import stepsSelBank from './components/stepsSelBank.vue'
import bottom from './components/bottom.vue'
import { bankRoutes } from '@/utils/bankList'
import Cookies from 'js-cookie'

@Component({
  name: 'selBank',
  components: {
    stepsSelBank,
    bottom
  }
})
export default class extends Vue {
  public loading: boolean = true
  public orderInfo: any = {
    amount: '',
    bank: [],
    is_expired: false,
    ordernumber: '',
    status: 0
  }
  public config: any = {}
  public stepOps: any[] = []
  public action: number = 0
  public currentBank: any = {}

  public selBank(bank: any) {
    this.currentBank = bank
    this.action = 1
  }

  public goBankLogin() {
    this.action = 2
  }

  @Watch('action', { immediate: true })
  public async onActionChange() {
    if (this.action === 2) {
      try {
        await this.quickpayBindBank()
        await this.$router.replace({
          name: bankRoutes[this.currentBank.bank_name] || 'general',
          params: { orderInfo: this.orderInfo }
        })
      } catch (e) {}
    }
  }

  public async quickpayBindBank() {
    try {
      const data: any = {
        paybank: this.currentBank.id
      }
      await quickpayBindBank(data).then(() => {})
    } catch (e) {}
  }
  public getLogo(bank: any) {
    try {
      return require(`@/assets/bank-logo/quickpay_bank/${Cookies.get(
        'currency'
      )}/${bank.bank_code}.png`)
    } catch (e) {
      return bank.logo
    }
  }

  public async getCounter() {
    try {
      await getCounter().then((res: any) => {
        let language =
          res.currency === 'THB' ? 'th' : res.currency === 'VND' ? 'vi' : 'en'
        this.$i18n.locale = language
        AppModule.SetLanguage(language)
        this.stepOps = [
          this.$t('online.steps.step1'),
          this.$t('online.steps.step2'),
          this.$t('online.steps.step3')
        ]
        if (res.status === 0 && res.is_expired) {
          // 订单未支付 且订单过期
          router.replace({ name: 'error' })
        } else if (res.status === 1) {
          // 订单已支付
          router.replace({ name: 'success' })
        } else if (res.extra_param?.paybank) {
          this.$router.replace({
            name:
              bankRoutes[
                this.currentBank.bank_name || res.extra_param.bank_name
              ] || 'general',
            params: { orderInfo: this.orderInfo }
          })
        } else {
          // 订单未支付 且订单未过期
          this.orderInfo = res
        }
        this.loading = false
      })
    } catch (e) {
      this.loading = false
    }
  }

  created() {
    AppModule.SetNavbar(false)
  }
  mounted() {
    this.getCounter()
  }
}
